import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import AppContext from "../contexts/App";

import SecondaryNav, { SecondaryNavTitle } from "./SecondaryNav";
import { getAndReturnJSON, postAndReturnJSON } from "../helpers/api";
import Loader from "./Loader";
import AutocompleteFormGroup from "./Form/AutocompleteFormGroup";

const UserProfile = ({ id, customer, payee, setActiveProfile, active }) => {
  return (
    <button
      type="button"
      className={`list-group-item list-group-item-action`}
      onClick={() => {
        setActiveProfile(id);
      }}
    >
      {customer && customer.name}
      {customer && <span className="ml-2 badge badge-primary">Admin</span>}
      {payee && payee.name}
      {payee && <span className="ml-2 badge badge-warning">Payee</span>}
      {payee && payee.customer_data && (
        <span className="ml-2 badge badge-info">
          {payee.customer_data.name}
        </span>
      )}
      {active && <span className="ml-2 badge badge-success">Active</span>}
    </button>
  );
};
UserProfile.propTypes = {
  id: PropTypes.number,
  customer: PropTypes.object,
  payee: PropTypes.object,
  setActiveProfile: PropTypes.func,
  active: PropTypes.bool,
};

export const UserProfileList = ({ onSwitch = null }) => {
  const { role, userIsStaff, userId, userProfile, setUserProfile } =
    useContext(AppContext);
  const profileId = userProfile?.id;
  const [userProfiles, setUserProfiles] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [payee, setPayee] = useState(null);
  const activeProfile = profileId && parseInt(profileId);
  const isStaffOrCustomer = userIsStaff || role === "customer";
  const history = useHistory();

  // load data on mount
  useEffect(() => {
    setLoading(true);
    getAndReturnJSON({
      url: "/api/v1/user-profiles/",
      params: { user: userId },
    }).then((response) => {
      setUserProfiles(response.results);
      setLoading(false);
    });
  }, [userId]);

  const handleProfileChange = (id) => {
    postAndReturnJSON({
      url: "/api/v1/set-active-user-profile/",
      data: { active_user_profile: id },
    }).then((response) => {
      setUserProfile(response);
      if (onSwitch) onSwitch();
      else history.push("/");
    });
  };

  if (loading) return <Loader />;

  const _userProfiles =
    userProfiles &&
    userProfiles.map((userProfile) => (
      <UserProfile
        key={userProfile.id}
        active={userProfile.id === activeProfile}
        setActiveProfile={handleProfileChange}
        {...userProfile}
      />
    ));

  return (
    <div className="list-group">
      {userIsStaff && (
        <button
          type="button"
          className={`list-group-item list-group-item-action`}
          onClick={() => handleProfileChange(false)}
        >
          Staff
        </button>
      )}
      {_userProfiles}
      {isStaffOrCustomer && (
        <div className="mt-4">
          <h5 className="mb-4">
            Choose a specific {userIsStaff && "customer or"} payee
          </h5>
          {userIsStaff && (
            <>
              <AutocompleteFormGroup
                label="Customer"
                autocompleteUrl="/api/v1/autocomplete/customer/"
                onChange={(value) => {
                  setPayee(false);
                  setCustomer(value);
                  postAndReturnJSON({
                    url: "/api/v1/set-active-user-profile/",
                    data: { active_user_profile_customer_id: value.value },
                  }).then((response) => {
                    setUserProfile(response);
                    history.push("/");
                  });
                }}
                value={customer}
                showAll={true}
              />
            </>
          )}
          <AutocompleteFormGroup
            label="Payee"
            autocompleteUrl="/api/v1/autocomplete/payee/"
            onChange={(value) => {
              setCustomer(false);
              setPayee(value);
              const data = {
                active_user_profile_payee_id: value.value,
              };
              if (role === "customer") {
                data["active_user_profile"] = profileId && parseInt(profileId);
              }
              postAndReturnJSON({
                url: "/api/v1/set-active-user-profile/",
                data: data,
              }).then((response) => {
                setUserProfile(response);
                history.push("/");
              });
            }}
            value={payee}
            showAll={true}
          />
        </div>
      )}
    </div>
  );
};

UserProfileList.propTypes = {
  onSwitch: PropTypes.func,
};

const UserProfiles = () => {
  useEffect(() => {
    document.title = `Switch Account · Infinite Catalog`;
  }, []);

  return (
    <div>
      <SecondaryNav>
        <SecondaryNavTitle title="Switch Account" />
      </SecondaryNav>
      <div className="container-fluid">
        <UserProfileList />
      </div>
    </div>
  );
};

UserProfiles.propTypes = {};

export default UserProfiles;
