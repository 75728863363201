import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { MODEL_OPTIONS } from "../ModelBadge";
import { getLoadAutocompleteOptions } from "../../helpers/api";
import { VALID_GREEN } from "../../constants";
import { overlayStyles } from "../Modal";

function MenuList({ selectProps, children, ...props }) {
  return (
    <components.MenuList {...props}>
      {children}
      <div className="text-center py-2">
        <small>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              selectProps.setShowAdvancedSearch(true);
            }}
          >
            Use Advanced Search
          </a>
        </small>
      </div>
    </components.MenuList>
  );
}
MenuList.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

function NoOptionsMessage({ selectProps, children, ...props }) {
  return (
    <components.NoOptionsMessage {...props}>
      {selectProps.inputValue === ""
        ? "Please enter a search term..."
        : children}
    </components.NoOptionsMessage>
  );
}
NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

function AdvancedSearch({
  setShowAdvancedSearch,
  advancedSearchComponent: AdvancedSearchComponent,
  customerId,
  role,
  onChange,
}) {
  return (
    <div
      style={overlayStyles}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="modal d-block" tabIndex="-1" role="dialog">
        <div
          className="modal-dialog"
          role="document"
          style={{
            maxWidth: "none",
            height: "100vh",
            padding: "0 1rem 1rem",
            boxSizing: "border-box",
          }}
        >
          <div
            className="modal-content"
            style={{ height: "calc(100% - 100px)" }}
          >
            <div className="modal-header">
              <h5 className="modal-title">Advanced Search</h5>
              <button
                type="button"
                className="close"
                tabIndex={-1}
                onClick={() => setShowAdvancedSearch(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: "scroll" }}>
              <AdvancedSearchComponent
                customerId={customerId}
                role={role}
                onChange={(value) => {
                  onChange(value);
                  setShowAdvancedSearch(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
AdvancedSearch.propTypes = {
  setShowAdvancedSearch: PropTypes.func.isRequired,
  advancedSearchComponent: PropTypes.elementType,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  role: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Autocomplete = ({
  customerId,
  role,
  onChange,
  url,
  autoFocus = false,
  isDisabled = false,
  isClearable = false,
  isMulti = false,
  valid = true,
  validate = false,
  value = undefined,
  showAll = false,
  model = null,
  advancedSearchComponent = null,
  ...extraProps
}) => {
  // track whether component is mounted, to cancel loadOptions callback
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });
  const loadOptions = getLoadAutocompleteOptions({ url, customerId, showAll });
  const styles = {};
  if (validate && !valid) styles.borderColor = "red";
  else if (validate) styles.borderColor = VALID_GREEN;

  const components = advancedSearchComponent
    ? { MenuList, NoOptionsMessage }
    : { NoOptionsMessage };

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  return (
    <div>
      {showAdvancedSearch && (
        <AdvancedSearch
          setShowAdvancedSearch={setShowAdvancedSearch}
          advancedSearchComponent={advancedSearchComponent}
          customerId={customerId}
          role={role}
          onChange={(_value) => {
            if (!isMulti) onChange(_value);
            else if (!value) {
              onChange([_value]);
            } else {
              onChange([...value, _value]);
            }
          }}
        />
      )}
      <AsyncSelect
        autoFocus={autoFocus}
        isDisabled={isDisabled}
        isMulti={isMulti}
        onChange={onChange}
        isClearable={isClearable}
        loadOptions={(inputValue, callback) =>
          loadOptions(inputValue, callback, isMounted.current)
        }
        value={value}
        styles={{
          control: (base) => ({ ...base, ...styles }),
          multiValue: (provided) => {
            if (!model) return provided;
            const options = MODEL_OPTIONS[model];
            const newStyles = { backgroundColor: options.background };
            if (options.className === "text-light") newStyles.color = "white";
            return { ...provided, ...newStyles };
          },
          multiValueLabel: (provided) => {
            if (!model) return provided;
            const options = MODEL_OPTIONS[model];
            const newStyles = {};
            if (options.className === "text-light") newStyles.color = "white";
            return { ...provided, ...newStyles };
          },
        }}
        components={components}
        setShowAdvancedSearch={setShowAdvancedSearch}
        {...extraProps}
      />
    </div>
  );
};
Autocomplete.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  role: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  valid: PropTypes.bool,
  validate: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  showAll: PropTypes.bool,
  model: PropTypes.string,
  advancedSearchComponent: PropTypes.elementType,
};

export default Autocomplete;
