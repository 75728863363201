import React, { useContext } from "react";

import AppContext from "../contexts/App";
import { UserProfileList } from "./UserProfiles";

function NotFound() {
  const { userProfileCount } = useContext(AppContext);

  return (
    <div className="container pt-5">
      <div className="text-center">
        <h1 className="display-3">Not Found</h1>
        <p className="lead">Sorry, we couldn&apos;t find that 😞</p>
      </div>
      {userProfileCount > 1 && (
        <div className="mt-5">
          <p>
            You may be using the incorrect account, try switching accounts
            below:
          </p>
          <UserProfileList onSwitch={() => window.location.reload()} />
        </div>
      )}
    </div>
  );
}

export default NotFound;
