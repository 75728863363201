import Cookies from "js-cookie";

/*
 * Make an API request with credentials
 */
async function makeAPIRequest({
  url,
  method,
  data = null,
  contentType = "application/JSON",
  signal = null,
}) {
  const _url = url.startsWith("http")
    ? url
    : `${process.env.REACT_APP_API_ROOT}${url}`;
  const params = {
    method,
    credentials: "include",
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": contentType,
    },
    signal,
  };
  if (data && contentType === "application/json")
    params.body = JSON.stringify(data);
  else if (data) params.body = data;

  // using form data means we need to manually remove Content-Type header
  // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
  if (contentType === "multipart/form-data")
    delete params.headers["Content-Type"];

  const response = await fetch(_url, params);
  if (response.ok) {
    if (response.status === 204) {
      return Promise.resolve({});
    }
    return Promise.resolve(response.json());
  } else {
    // handle response differently based on content type
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.startsWith("application/json")) {
      const errorJSON = await response.json();
      const errorData = { data: errorJSON, status: response.status };
      return Promise.reject(errorData);
    } else {
      return Promise.reject({
        message: response.statusText,
        status: response.status,
      });
    }
  }
}

/*
 * Make an API request, if access token is expired, refresh it
 */
async function makeRequestAndReturnJSON({
  url,
  method,
  data = null,
  contentType = "application/JSON",
  signal = null,
}) {
  try {
    const response = await makeAPIRequest({
      url,
      method,
      data,
      contentType,
      signal,
    });
    return Promise.resolve(response);
  } catch (e) {
    // if access token is invalid, refresh it, and try the request again
    if (e.status === 401 && e.data?.code === "token_not_valid") {
      try {
        await makeAPIRequest({ url: "/api/v1/token/refresh/", method: "POST" });
        try {
          const retryResponse = await makeAPIRequest({
            url,
            method,
            data,
            contentType,
            signal,
          });
          return Promise.resolve(retryResponse);
        } catch (retryError) {
          return Promise.reject(retryError);
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else {
      return Promise.reject(e);
    }
  }
}

function postAndReturnJSON({
  url,
  data = null,
  method = "POST",
  contentType = "application/json",
}) {
  return makeRequestAndReturnJSON({
    url,
    method,
    data,
    contentType,
  });
}

function getQueryStringFromParams(params) {
  let queryString = "";
  if (Object.keys(params).length > 0) {
    queryString =
      "?" +
      Object.keys(params)
        .map((key) => key + "=" + encodeURIComponent(params[key]))
        .join("&");
  }

  return queryString;
}

function getAndReturnJSON({ url, params = {}, signal = null }) {
  // querystring
  const queryString = getQueryStringFromParams(params);
  return makeRequestAndReturnJSON({
    url: `${url}${queryString}`,
    method: "GET",
    signal,
  });
}

function getLoadAutocompleteOptions({ url, customerId, showAll = false }) {
  return async function (inputValue, callback, isMounted) {
    const params = {
      query: inputValue ? inputValue : "",
    };
    if (customerId) params.customer_id = customerId;
    if (showAll) params.show_all = "1";

    const data = await getAndReturnJSON({ url, params });
    const results = data.results.map((result) => ({
      value: parseInt(result.id),
      label: result.name,
      data: result,
    }));
    if (isMounted) callback(results);
  };
}

function getIdFromAPIUrl(url) {
  const split = url.split("/");
  return split[split.length - 2];
}

async function getAllPages({ url, params, limit = 50 }) {
  const _params = Object.assign({}, params, { limit, offset: 0 });
  const response = { results: [] };

  let hasNext = true;
  let nextUrl = false;

  while (hasNext) {
    const _response = nextUrl
      ? await getAndReturnJSON({ url: nextUrl })
      : await getAndReturnJSON({ url, params: _params });
    response.results.push(..._response.results);
    response.count = _response.count;

    if (!_response.next) {
      hasNext = false;
    } else {
      nextUrl = _response.next;
    }
  }
  return response;
}

async function getPaginated({ url, params, limit = 0, offset = 0 }) {
  const _params = Object.assign({}, params, { limit, offset });
  const response = await getAndReturnJSON({ url, params: _params });

  return response;
}

export {
  getAndReturnJSON,
  getAllPages,
  getIdFromAPIUrl,
  getLoadAutocompleteOptions,
  getQueryStringFromParams,
  makeRequestAndReturnJSON,
  postAndReturnJSON,
  getPaginated,
};
