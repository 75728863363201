function AppLoading() {
  return (
    <div className="app-js-loading-screen">
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          width="60"
          alt="Infinite Catalog"
        />
        <div className="loading-text">Loading</div>
      </div>
    </div>
  );
}

export default AppLoading;
