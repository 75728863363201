import React from "react";
import PropTypes from "prop-types";

import { COLORS, COLORS_PASTEL } from "../../constants";

export const MODEL_OPTIONS = {
  customer: {
    background: COLORS_PASTEL[11],
    name: "Customer",
    className: "text-dark",
  },
  artist: {
    background: COLORS_PASTEL[3],
    name: "Artist",
    className: "text-dark",
  },
  release: {
    background: COLORS_PASTEL[2],
    name: "Release",
    className: "text-dark",
  },
  track: {
    background: COLORS[6],
    name: "Track",
    className: "text-dark",
  },
  item: {
    background: COLORS_PASTEL[7],
    name: "Item",
    className: "text-dark",
  },
  transaction: {
    background: COLORS[0],
    name: "Transaction",
    className: "text-dark",
  },
  csvImport: {
    background: COLORS_PASTEL[10],
    name: "Data Import",
    className: "text-dark",
  },
  period: {
    background: COLORS[1],
    name: "Period",
    className: "text-light",
  },
  contract: {
    background: COLORS[2],
    name: "Contract",
    className: "text-dark",
  },
  split: {
    background: COLORS[2],
    name: "Split",
    className: "text-dark",
  },
  payout: {
    background: COLORS[9],
    name: "Payout",
    className: "text-light",
  },
  payee: {
    background: COLORS[10],
    name: "Payee",
    className: "text-dark",
  },
  ledger: {
    background: COLORS_PASTEL[5],
    name: "Ledger",
    className: "text-dark",
  },
  payeeSummary: {
    background: COLORS_PASTEL[0],
    name: "Payee Summary",
    className: "text-dark",
  },
  statement: {
    background: COLORS[8],
    name: "statement",
    className: "text-dark",
  },
  statementGroup: {
    background: COLORS[8],
    name: "Statement Group",
    className: "text-dark",
  },
  reports: {
    background: COLORS[7],
    name: "reports",
    className: "text-dark",
  },
  mechanicals: {
    background: "#669999",
    name: "Mechanicals",
    className: "text-light",
  },
  ppd: {
    background: "#669999",
    name: "PPD",
    className: "text-light",
  },
  reserves: {
    background: "#669999",
    name: "Reserves",
    className: "text-light",
  },
};

// mapping of django content type models to MODEL_OPTIONS keys
export const CONTENT_TYPE_MODELS = {
  artist: "artist",
  release: "release",
  track: "track",
  item: "item",
  transaction: "transaction",
  csvimport: "csvImport",
  period: "period",
  contract: "contract",
  payout: "payout",
  payee: "payee",
  ledger: "ledger",
  period_payee: "payeeSummary",
  split: "split",
  statement: "statement",
  statement_group: "statementGroup",
};

const ModelBadge = ({
  model,
  className = "",
  contentTypeModel = null,
  body = false,
}) => {
  const _model = contentTypeModel
    ? CONTENT_TYPE_MODELS[contentTypeModel]
    : model;
  const options = MODEL_OPTIONS[_model];
  return (
    <span
      className={`badge text-capitalize ${
        options ? options.className : ""
      } ${className}`}
      style={options ? { backgroundColor: options.background } : {}}
    >
      {body ? body : options ? options.name : model}
    </span>
  );
};
ModelBadge.propTypes = {
  model: PropTypes.string,
  className: PropTypes.string,
  contentTypeModel: PropTypes.string,
  body: PropTypes.node,
};

export const ModelBadgeList = () => {
  return (
    <div className="my-5 p-5 border">
      <h3>Model Badges</h3>
      <ModelBadge className="mb-2" model="artist" />
      <br />
      <ModelBadge className="mb-2" model="release" />
      <br />
      <ModelBadge className="mb-2" model="track" />
      <br />
      <ModelBadge className="mb-2" model="item" />
      <br />
      <ModelBadge className="mb-2" model="transaction" />
      <br />
      <ModelBadge className="mb-2" model="csvImport" />
      <br />
      <ModelBadge className="mb-2" model="period" />
      <br />
      <ModelBadge className="mb-2" model="contract" />
      <br />
      <ModelBadge className="mb-2" model="payee" />
      <br />
      <ModelBadge className="mb-2" model="ledger" />
      <br />
      <ModelBadge className="mb-2" model="payeeSummary" />
      <br />
      <ModelBadge className="mb-2" model="statement" />
      <br />
      <ModelBadge className="mb-2" model="payout" />
      <br />
    </div>
  );
};

export default ModelBadge;
