import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

export const overlayStyles = {
  position: "fixed",
  background: "rgba(255, 255, 255, 0.7)",
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 1021,
};

const Modal = ({
  title,
  body,
  handleCancel,
  handleConfirm,
  confirmText = "OK",
  cancelText = "Cancel",
  disableConfirm = false,
  disableCancel = false,
}) => {
  let confirmButtonStyles = null;
  if (disableConfirm) {
    confirmButtonStyles = {
      pointerEvents: "none",
    };
  }
  const confirmRef = useRef(null);
  useEffect(() => {
    !disableConfirm && confirmRef.current && confirmRef.current.focus();
    const keyDown = (e) => {
      if (e.key === "Escape") handleCancel();
    };

    document.addEventListener("keydown", keyDown, false);
    return function cleanup() {
      document.removeEventListener("keydown", keyDown, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={overlayStyles}
      className="d-flex align-items-center justify-content-center"
    >
      <div className="modal d-block" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                tabIndex={-1}
                onClick={handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{body}</div>
            <div className="modal-footer">
              <button
                type="button"
                tabIndex={-1}
                className={`btn btn-secondary ${disableCancel && "disabled"}`}
                onClick={!disableCancel ? handleCancel : undefined}
              >
                {cancelText}
              </button>
              <button
                ref={confirmRef}
                type="button"
                className={`btn btn-primary ${disableConfirm && "disabled"}`}
                onClick={!disableConfirm ? handleConfirm : undefined}
                style={confirmButtonStyles}
              >
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disableConfirm: PropTypes.bool,
};

export default Modal;
