import React from "react";
import PropTypes from "prop-types";

import Autocomplete from "../Autocomplete";

const AutocompleteFormGroup = ({
  customerId,
  role,
  label,
  onChange,
  value,
  autocompleteUrl,
  onCreate = null,
  createLabel = "",
  onEdit = null,
  editLabel = "",
  validate = false,
  valid = true,
  helpText = false,
  required = false,
  showAll = false,
  isClearable = true,
  error = null,
  ...extraProps
}) => {
  const hasError = (required && validate && !value) || error;
  const styles = hasError ? { borderColor: "red" } : {};

  return (
    <div className="form-group">
      {label && (
        <label>
          {label}
          {required && "*"}
        </label>
      )}
      <div className="d-flex">
        <div className="flex-grow-1">
          <Autocomplete
            url={autocompleteUrl}
            customerId={customerId}
            role={role}
            onChange={onChange}
            value={value}
            isClearable={isClearable}
            valid={valid}
            validate={validate}
            showAll={showAll}
            styles={{
              control: (base) => ({ ...base, ...styles }),
            }}
            {...extraProps}
          />
        </div>
        {(onCreate || onEdit) && (
          <div className="btn-group btn-group-sm ml-2 mt-2">
            {onEdit && value && (
              <button
                type="button"
                className="btn text-primary"
                tabIndex="-1"
                onClick={() => {
                  onEdit();
                }}
              >
                <i className="fas fa-edit" title={editLabel} />
                <span className="sr-only">{editLabel}</span>
              </button>
            )}
            {onCreate && (
              <button
                className="btn text-primary"
                type="button"
                tabIndex="-1"
                onClick={() => {
                  onCreate();
                }}
              >
                <i className="fas fa-plus" title={createLabel} />
                <span className="sr-only">{createLabel}</span>
              </button>
            )}
          </div>
        )}
      </div>
      {helpText && <small className="form-text text-muated">{helpText}</small>}
      {error && <small className="form-text text-danger">{error}</small>}
    </div>
  );
};
AutocompleteFormGroup.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  role: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  autocompleteUrl: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  createLabel: PropTypes.string,
  onEdit: PropTypes.func,
  editLabel: PropTypes.string,
  validate: PropTypes.bool,
  valid: PropTypes.bool,
  helpText: PropTypes.node,
  required: PropTypes.bool,
  showAll: PropTypes.bool,
  isClearable: PropTypes.bool,
  error: PropTypes.object,
};

export default AutocompleteFormGroup;
