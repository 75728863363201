const COLORS = [
  "#a6cee3",
  "#1f78b4",
  "#b2df8a",
  "#33a02c",
  "#fb9a99",
  "#e31a1c",
  "#fdbf6f",
  "#ff7f00",
  "#cab2d6",
  "#6a3d9a",
  "#ffff99",
  "#b15928",
];

const COLORS_PASTEL = [
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
];

// drag and drop types
const DND_TYPES = {
  CATALOG: "catalog",
};

const VALID_GREEN = "#28a745";

const ALERT_VISIBLE_TIME = 4000; // 4 seconds

const PLACEHOLDER_IMAGE_URL =
  "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/76/multiple-musical-notes_1f3b6.png";

const EXPENSE_CATEGORIES = [
  "Advertising",
  "Artwork/Design",
  "Bio",
  "Distribution Fee",
  "Event",
  "Fee",
  "License Fee",
  "Manufacturing (LP)",
  "Manufacturing (CD)",
  "Manufacturing (Misc.)",
  "Marketing",
  "Mastering",
  "Mixing",
  "Photos",
  "PR",
  "Radio",
  "Recording - Mixing",
  "Recording - Musician",
  "Recording - Producer",
  "Recording - Studio",
  "Remix",
  "Return Processing Fee",
  "Rights",
  "Shipping/Freight",
  "Storage Fee",
  "Studio",
  "Ticket Buy",
  "Tour Support",
  "Video",
];

const INCOME_CATEGORIES = [
  "3rd Party License",
  "Album License",
  "Artist Copies",
  "Direct to Fan/Show",
  "Direct to Fan/Webstore",
  "Distribution",
  "Grant Funding",
  "Master Use License",
  "Master/Sync License (all in)",
  "PPD",
  "Publishing Royalties",
  "Publishing Sync License",
  "Refund",
  "Reserves",
  "Rights",
  "Sample Usage License",
  "Sub-Label",
  "Ticket Sales/Show Fee",
  "Wholesale",
];

const TRANSFER_CATEGORIES = [
  "Advance",
  "Artist Merch Given",
  "Artist Payback to Label",
  "Event",
  "Legal Services",
  "Mastering",
  "Mechanicals",
  "Previous Balance",
  "Studio",
  "Tour Support",
];

const CHECKMARK_EMOJI_UNICODE = "\u2705";

const ONBOARDING_URL = "https://calendly.com/d/496-7bx-2s4";

export {
  COLORS,
  COLORS_PASTEL,
  DND_TYPES,
  VALID_GREEN,
  ALERT_VISIBLE_TIME,
  PLACEHOLDER_IMAGE_URL,
  INCOME_CATEGORIES,
  EXPENSE_CATEGORIES,
  TRANSFER_CATEGORIES,
  CHECKMARK_EMOJI_UNICODE,
  ONBOARDING_URL,
};
