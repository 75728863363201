import * as React from "react";
import * as PropTypes from "prop-types";
import "../../css/ball-clip-rotate.min.css";

interface Props {
  label?: React.ReactNode;
}

function ICLoader({ label = undefined }: Props) {
  return (
    <div
      className="p-4 loader d-flex align-items-center justify-content-center"
      title="Loading"
    >
      <div className="la-ball-clip-rotate" style={{ color: "#ccc" }}>
        <div />
      </div>
      {label && (
        <div className="ml-2 text-muted font-italic">{label}&#8230;</div>
      )}
    </div>
  );
}
ICLoader.propTypes = {
  label: PropTypes.node,
};

export default ICLoader;
